import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CommonContentService } from '../../api/Client';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _storedKeys: any = {};
  constructor(
    private _client: CommonContentService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  async Translate(key: string, params: any = []): Promise<string> {
    if (isPlatformBrowser(this.platformId)) {
      var value = null;
      if (this._storedKeys[key] == undefined) {
        var result = await lastValueFrom(this._client.translation(key));
        this._storedKeys[key] = result.value;
        value = result.value as string;
      } else value = this._storedKeys[key] as string;
      return this.formatString(value, params);
    }
    return '[' + key + ']';
  }

  private formatString(template: string, params: any) {
    return template.replace(/{{(\d+)}}/g, (match, index) => {
      return typeof params[index] !== 'undefined' ? params[index] : match;
    });
  }
}
