const baseUrl = 'https://gw.tanmiah.gov.sa';
const umbracoBaseUrl = 'https://gw.tanmiah.gov.sa';
const appBaseUrl = 'https://tanmiah.gov.sa';

export const environment = {
  Base_APP_URL: appBaseUrl,
  production: true,
  recaptcha: {
    siteKey: '6LdynKkqAAAAALDkTEr3MSU7rFF4yDIxQfdYu9zz',
  },
  googleMapsApiKey: 'AIzaSyBrKmC8R6jt_K0JlXkKq9qUUIGyv2JJ0SY',
  apis: {
    default: {
      url: baseUrl,
      backToBackUrl: 'http://10.217.10.215',
    },
    mediaDeliveryAPI: {
      url: umbracoBaseUrl,
    },
  },
};
