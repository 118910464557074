import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appListAnimation]',
  standalone: true,
})
export class ListAnimationDirective implements AfterViewInit {

  @Input() listClassName: string = ''; // Class name to target for animation

  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    if (this.isInViewport(this.el.nativeElement)) {
      this.showList();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (this.isInViewport(this.el.nativeElement)) {
      this.showList();
    }
  }

  // Check if the element is in the viewport
  isInViewport(element: any): boolean {
    const rect = element.getBoundingClientRect();    
    return rect.top + 100 <= window.innerHeight;
  }


  // Show partners by adding visibility classes with delay
  showList(): void {    
    if (this.listClassName !== '') {   
        const partnerItems = document.querySelectorAll(`.${this.listClassName}`);
        partnerItems.forEach((item: any, index: number) => {    
            item.style.transitionDelay = this.getTransitionDelay(index);
        
          setTimeout(() => {
            item.classList.add('list-item-visible');
          }, index * 100); // Apply delay based on index
        });
    }
  }
  getTransitionDelay(index: number): string {
    return `${index * 50}ms`;  // Add 50ms delay for each item based on its index
  }
}
