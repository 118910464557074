import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollDetect]',
  standalone: true,
})
export class ScrollDetectDirective {
  private hasBeenShown = false; // Track if the element has already been shown

  constructor(private el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop; // Get the position of the element
    const scrollPosition = window.scrollY || window.scrollY; // Get the current scroll position

    // If the element has not been shown yet and it is scrolled into view
    if (
      !this.hasBeenShown &&
      scrollPosition + window.innerHeight >= componentPosition + 250
    ) {
      this.hasBeenShown = true;
      this.showElement();
    }
  }

  private showElement() {
    const element = this.el.nativeElement;
    element.classList.add('show-fade');
    element.classList.remove('hide-fade');
  }
}
