import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CotnentLoadingService {
  fullyLoadedState = signal(false);
  constructor() {}
  // Method to update the signal
  setLoadingState(fullyLoaded: boolean): void {
    this.fullyLoadedState.set(fullyLoaded);
  }
}
