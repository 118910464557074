import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import {
  CommonContentService,
  TranslationResultDto,
} from '../../../api/Client';
import { TranslationService } from '../../services/translation.service';

@Pipe({
  name: 'umbracoTranslate',
  standalone: true,
})
export class UmbracoTranslatePipe implements PipeTransform {
  private translatedText: string = '';
  private lastKey: string = '';

  constructor(
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef // To trigger change detection
  ) {}

  transform(value: string): Observable<string> {
    return (from(this.translationService.Translate(value))).pipe(
      map((response: any) => response) // Assuming the string is in response.Value
    );
  }
}
