import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberAnimation]',
  standalone: true,
})
export class NumberAnimationDirective implements AfterViewInit {
  private isAnimated = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.checkIfInView();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.checkIfInView();
  }

  checkIfInView(): void {
    const element = this.el.nativeElement;
    const rect = element.getBoundingClientRect();
    const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (isInView && !this.isAnimated) {
      this.isAnimated = true;

      // Extract the content
      const content = element.textContent.trim();

      // Extract prefix, numeric value, and suffix
      const match = content.match(/^([^\d]*)([\d,]+)(.*)$/);

      if (match) {
        const prefix = match[1]; // Text before the number
        const numericValue = parseInt(match[2].replace(/,/g, ''), 10); // Numeric part
        const suffix = match[3]; // Text after the number

        if (!isNaN(numericValue) && numericValue > 0) {
          this.animateNumber(numericValue, prefix, suffix);
        }
      }
    }
  }

  animateNumber(targetNumber: number, prefix: string, suffix: string): void {
    let currentNumber = 0;
    const maxDuration = 3000; // Maximum duration for animation (in ms)
    const minDuration = 1000; // Minimum duration for animation (in ms)

    // Calculate animation duration based on the target number
    const duration = Math.min(maxDuration, Math.max(minDuration, targetNumber / 5));

    const startTime = performance.now();

    const animate = (time: number) => {
      const elapsedTime = time - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress doesn't exceed 1

      // Smoothly calculate the current number
      currentNumber = Math.floor(targetNumber * progress);

      // Format the current number with commas
      const formattedNumber = currentNumber.toLocaleString('en-US');

      // Update the element content
      this.el.nativeElement.textContent = `${prefix}${formattedNumber}${suffix}`;

      if (progress < 1) {
        requestAnimationFrame(animate); // Continue animation
      } else {
        // Ensure the final value is exactly the target number
        this.el.nativeElement.textContent = `${prefix}${targetNumber.toLocaleString('en-US')}${suffix}`;
      }
    };

    requestAnimationFrame(animate);
  }
}
