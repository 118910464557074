import { Injectable, signal } from '@angular/core';
import { SliderDto } from '../../api/Client';

@Injectable({
  providedIn: 'root',
})
export class DefaultValueOverrideService {
  currentPattern = signal({headerTube:'', icon:'', fontColor:''});

  // Method to update the signal
  setCurrentPattern(PatternExtention: {headerTube:string, icon:string, fontColor:string}): void {
    if (PatternExtention !== null && PatternExtention !== undefined)
      this.currentPattern.set(PatternExtention);
  }
}
