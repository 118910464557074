import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'sanitizedHtml',
  standalone: true,
})
export class SanitizedHtmlPipe implements PipeTransform {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any): any {
    if (value) 
    return this.sanitized.bypassSecurityTrustHtml(
      value?.replaceAll !== undefined
        ? value
            .replaceAll(
              'src="/umbraco-media/',
              'src="' + this.MediaUrl + '/umbraco-media/'
            )
            .replaceAll(
              'href="/umbraco-media/',
              'href="' + this.MediaUrl + '/umbraco-media/'
            )
        : value?.changingThisBreaksApplicationSecurity !== undefined
        ? value.changingThisBreaksApplicationSecurity
            .replaceAll(
              'src="/umbraco-media/',
              'src="' + this.MediaUrl + '/umbraco-media/'
            )
            .replaceAll(
              'href="/umbraco-media/',
              'href="' + this.MediaUrl + '/umbraco-media/'
            )
        : value
    );
  }
}
